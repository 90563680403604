.App {
  position: relative;
  overflow-x: clip;
}
/* .App>:nth-child(1){
  background: var(--black);
  position: relative;
} */
.white-gradient{
  position: absolute;
  width: 20rem;
  height: 20rem;
  background: rgba(255, 255, 255,0.522);
  filter: blur(100px);
  z-index: 90;
}
.whatsappIcon{
    position: fixed;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 99;
    transition: transform 0.3s ease;
    animation: pulse 2s infinite alternate; 
}

.whatsappIcon:hover {
  transform: scale(1.1); 
}

@keyframes pulse {
  0% {
    transform: scale(1); /* Initial size */
  }
  100% {
    transform: scale(1.1); /* Scaled size */
  }
}