.hero-wapper{
    color: white;
    position: relative;
    padding-bottom: 2rem;
    background-color: var(--black);
}
.hero-container{
    justify-content: space-around;
    align-items: flex-end;
}
.image-container{
    width: 25rem;
    height: 25rem;
    overflow: hidden;
    border-radius: 1rem 1rem 1rem 1rem;
    border: 8px solid rgba(255, 255, 255, 0.12);
    box-shadow: rgba(0, 0, 0, 0.5);
}
.image-container>img{
    width: 100%;
    height: 100%;
}
.hero-title{
    
    position: relative;
    z-index: 1;
}
.hero-title>h1{
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 4rem;
}
.hero-left{
    /* margin: auto; */
    gap: 3rem;
}
.orange-circle{
    height: 4rem;
    width: 4rem;
    background: var(--orange-gradient);
    border-radius: 999px;
    position: absolute;
    right: 35%;
    top: -8%;
    z-index: -1;
}
.hero-description{
    margin-top: 1rem;
    padding: 1rem;
    line-height: 1.5rem;
}
.stats{
    width: 100%;
    justify-content: space-between;
}
.stat{
    align-items: center;
}
.stat>:nth-child(1){
    font-size: 2rem;
}
.stat>:nth-child(1)>:last-child{
    color: orange;
}
@media(max-width:640px){
  
    .orange-circle{
        right: 50%;
    }
    .hero-title>h1{
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .image-container{
        width: 95%;
        height: 25rem;
    }
    .stats{
        justify-content: center;
        gap: 1.5rem;
    }
    .hero-right{
        width: 100%;
    }
    .stat>:nth-child(1) {
        font-size: 1.5rem;
    }
    .stats>:nth-child(2){
        font-size: 0.8rem;
    }
}
@media(max-width:360px){
    .orange-circle{
        right: 10%;
    }
}