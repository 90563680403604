.c-wapper{
    overflow-x: hidden;
}
.c-container{
    justify-content: space-around;
    gap: 1;
}
.c-container>img{
    width: 9rem;
}
.c-wrapper>h1{
    margin-top: 1rem;
}