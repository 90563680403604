.p-container {
    overflow: hidden;
    position: relative;
}

.p-head {
    margin-bottom: 2rem;
}

.p-card {
    gap: 0.6rem;
    padding: 0.5rem;
    border-radius: 10px;
    max-width: max-content;
    margin: auto;
    transition: all 300ms ease-in;
}

.p-card>img {
    width: 15rem;
    height: 15rem;
    max-width: 15rem;
    object-fit: inherit;
    border-radius: 1rem;
}

.p-card>:nth-child(2) {
    font-size: 1.2rem;
    font-weight: 600;
}

.p-card>:nth-child(3) {
    text-wrap: nowrap;
    font-size: 1.5rem;
}

.p-card>:nth-child(4) {
    font-size: 0.9rem;
    width: 15rem;
}

.p-card:hover {
    scale: 1.025;
    cursor: pointer;
    background: linear-gradient(180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(136, 160, 255, 0.46) 217.91%);
    box-shadow: 0px 72px 49px -51px rgba(136, 160, 255, 0.21);
}

.swiper-horizontal {
    overflow: visible;
}

.p-buttons {
    position: absolute;
    gap: 1rem;
    top: -4rem;
    right: 0;
}

.p-buttons button {
    font-size: 1.2rem;
    padding: 0.2rem 0.8rem;
    color: blue;
    border: none;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
}

.p-buttons>:nth-child(1) {
    background-color: #EEEEFF;
}

.p-buttons>:nth-child(2) {
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
}

@media(max-width:640px) {
    .p-head {
        align-items: center;
    }

    .p-buttons {
        position: initial;
    }
}